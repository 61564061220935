// Build information, automatically generated by `ng-info`
const build = {
    version: "2.24.0",
    timestamp: "Thu Jan 09 2025 19:38:06 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "0a9c84",
        fullHash: "0a9c847aa4d91fd2b742c702788d572cc23a907e"
    }
};

export default build;